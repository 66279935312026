import * as t from "io-ts";
import { eq, option } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import {
  AddressCountries,
  AddressType,
  AddressWrite,
  AllCitizenships,
  AllCountries,
  CitySuggestion,
  ClientDataEdit,
  ClientDataOCR,
  ClientDataResult,
  eqAddressType,
  eqAddressWrite,
  eqPersonalDocumentOfAddress,
  FraudCheckResult,
  PersonalDocumentProofOfAddress,
  PersonalInfoError,
  PersonalProfileDocUploadSubmitError,
  StreetSuggestion,
} from "./domain";
import { apiCall } from "../APICall";
import { constFalse, constTrue } from "fp-ts/function";
import {
  CompressedFileContent,
  CountryCode,
  DocumentPurpose,
  eqWithCoApplicant,
  GenericError,
  optionFromUndefined,
  withCoApplicant,
} from "../globalDomain";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { LocalizedString } from "design-system";
import { withApplicantIndex } from "../MortgageDashboard/domain";
import { TaskEither } from "fp-ts/TaskEither";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { NonResidentsAdditionalDocumentsType } from "../UKontoSecondPart/domain";

const IDStreetSuggestionsInput = t.type(
  {
    streetName: t.string,
    zipCode: t.string,
    cityName: t.string,
    provinceName: t.string,
    country: CountryCode,
  },
  "IDStreetSuggestionsInput"
);
interface IDStreetSuggestionsInput
  extends t.TypeOf<typeof IDStreetSuggestionsInput> {}
const eqIDStreetSuggestionsInput: Eq<IDStreetSuggestionsInput> = eq.getStructEq(
  {
    streetName: eq.eqString,
    zipCode: eq.eqString,
    cityName: eq.eqString,
    provinceName: eq.eqString,
  }
);
const IDStreetSuggestionsOutput = t.array(
  StreetSuggestion,
  "IDStreetSuggestionsOutput"
);
export interface IDStreetSuggestionsOutput
  extends t.TypeOf<typeof IDStreetSuggestionsOutput> {}
export const streetSuggestions = apiCall({
  inputEq: eqIDStreetSuggestionsInput,
  path: ["clients", "identification", "address", "streetSuggestions"],
  inputCodec: IDStreetSuggestionsInput,
  outputCodec: IDStreetSuggestionsOutput,
});

const IDAddressCountriesInput = t.void;
type IDAddressCountriesInput = t.TypeOf<typeof IDAddressCountriesInput>;
const eqIDAddressCountriesInput: Eq<IDAddressCountriesInput> = eq.fromEquals(
  constTrue
);
const IDAddressCountriesOutput = AddressCountries;
interface IDAddressCountriesOutput
  extends t.TypeOf<typeof IDAddressCountriesOutput> {}
export const countries = apiCall({
  inputEq: eqIDAddressCountriesInput,
  path: ["clients", "identification", "address", "countries"],
  inputCodec: IDAddressCountriesInput,
  outputCodec: IDAddressCountriesOutput,
});

const IDAllCountriesOutput = AllCountries;
export const allCountries = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "identification", "noauth", "address", "allCountries"],
  inputCodec: t.void,
  outputCodec: IDAllCountriesOutput,
});

export const allForeignSupportedCountries = apiCall({
  path: ["clients", "foreign", "noauth", "supportedCountries"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: IDAllCountriesOutput,
});

const IDCitySuggestionsInput = t.type(
  {
    searchTerm: t.string,
    country: CountryCode,
  },
  "IDCitySuggestionsInput"
);
interface IDCitySuggestionsInput
  extends t.TypeOf<typeof IDCitySuggestionsInput> {}
const eqIDCitySuggestionsInput: Eq<IDCitySuggestionsInput> = eq.getStructEq({
  searchTerm: eq.eqString,
});

const IDCitySuggestionsOutput = t.array(
  CitySuggestion,
  "IDCitySuggestionsOutput"
);

export interface IDCitySuggestionsOutput
  extends t.TypeOf<typeof IDCitySuggestionsOutput> {}
export const citySuggestions = apiCall({
  inputEq: eqIDCitySuggestionsInput,
  path: ["clients", "identification", "address", "citySuggestions"],
  inputCodec: IDCitySuggestionsInput,
  outputCodec: IDCitySuggestionsOutput,
});

export const IDAddressSubmitInput = withCoApplicant(
  t.type(
    {
      addressType: AddressType,
      address: AddressWrite,
    },
    "IDAddressSubmitInput"
  )
);
export type IDAddressSubmitInput = t.TypeOf<typeof IDAddressSubmitInput>;
export const eqIDAddressSubmitInput: Eq<IDAddressSubmitInput> = eqWithCoApplicant(
  eq.getStructEq({
    addressType: eqAddressType,
    address: eqAddressWrite,
  })
);

export const addressSubmit = apiCall({
  inputEq: eqIDAddressSubmitInput,
  path: ["clients", "identification", "address", "submit"],
  inputCodec: IDAddressSubmitInput,
});

export const ClientDataInput = withCoApplicant(
  t.type(
    {
      idType: optionFromUndefined(DocumentPurpose),
    },
    "ClientDataInput"
  )
);

export type ClientDataInput = t.TypeOf<typeof ClientDataInput>;

export const eqClientDataInput = eqWithCoApplicant(
  eq.getStructEq({
    idType: option.getEq(eq.eqString),
  })
);

export const ClientDataOutput = t.type({
  primary: optionFromUndefined(ClientDataOCR),
  secondary: optionFromUndefined(ClientDataOCR),
  result: ClientDataResult,
  canUploadAgain: t.boolean,
  documentMismatch: optionFromUndefined(t.boolean),
  extractedAddress: t.union([t.string, t.null, t.undefined]),
});
export type ClientDataOutput = t.TypeOf<typeof ClientDataOutput>;

export const clientData = apiCall({
  path: ["clients", "identification", "extractData"],
  inputCodec: ClientDataInput,
  inputEq: eqClientDataInput,
  outputCodec: ClientDataOutput,
});

export const clientDataPersonalProfile = apiCall({
  path: ["clients", "personalProfile", "docUpload", "extractData"],
  inputCodec: ClientDataInput,
  inputEq: eqClientDataInput,
  outputCodec: ClientDataOutput,
});

export type ExtractClientDataCommand = TaskEither<unknown, ClientDataOutput>;

const FraudCheckResultInput = t.type(
  {
    zenIdPurpose: t.keyof({
      FRAUD_CHECK_HOLOGRAM: true,
      FRAUD_CHECK_SELFIE: true,
      FRAUD_CHECK_SELFIE_AND_HOLOGRAM: true,
    }),
  },
  "FraudCheckResultInput"
);

export const fraudCheckResult = apiCall({
  path: ["clients", "identification", "fraudCheck"],
  inputCodec: FraudCheckResultInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: FraudCheckResult,
});

export const fraudCheckSelfie = apiCall({
  path: ["clients", "identification", "fraudCheck", "selfie"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: t.type({
    fileContent: optionFromNullable(CompressedFileContent),
  }),
});

const RejectDataInput = withCoApplicant(
  t.type({
    idType: optionFromUndefined(DocumentPurpose),
  })
);
type RejectDataInput = t.TypeOf<typeof RejectDataInput>;

export const rejectData = apiCall({
  path: ["clients", "identification", "extractData", "reject"],
  inputCodec: RejectDataInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const rejectDataUkonto = apiCall({
  path: ["packages", "uKonto", "identification", "extractData", "reject"],
  inputCodec: RejectDataInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const PersonalInfoEditInput = withCoApplicant(
  t.type({
    clientData: ClientDataEdit,
    idType: DocumentPurpose,
  })
);
export type PersonalInfoEditInput = t.TypeOf<typeof PersonalInfoEditInput>;
const PersonalInfoEditError = nonEmptyArray(LocalizedString);
export type PersonalInfoEditError = t.TypeOf<typeof PersonalInfoEditError>;

export const personalInfoEdit = apiCall({
  path: ["clients", "identification", "personalInfo", "edit"],
  inputCodec: PersonalInfoEditInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: PersonalInfoEditError,
});

export const personalInfoEditUkonto = apiCall({
  path: ["packages", "uKonto", "identification", "personalInfo", "edit"],
  inputCodec: PersonalInfoEditInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: PersonalInfoEditError,
});

const PersonalInfoSubmitInput = withCoApplicant(
  t.type({
    idType: DocumentPurpose,
  })
);
export type PersonalInfoSubmitInput = t.TypeOf<typeof PersonalInfoSubmitInput>;

export const personalInfoSubmit = apiCall({
  path: ["clients", "identification", "personalInfo", "submit"],
  inputCodec: PersonalInfoSubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: PersonalInfoError,
});

export const personalInfoSubmitUkonto = apiCall({
  path: ["packages", "uKonto", "identification", "personalInfo", "submit"],
  inputCodec: PersonalInfoSubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: PersonalProfileDocUploadSubmitError,
});

const ResidencySubmitInput = t.type({ residency: t.boolean });
export type ResidencySubmitInput = t.TypeOf<typeof ResidencySubmitInput>;
export const residencySubmitUkonto = apiCall({
  path: ["packages", "uKonto", "identification", "residency", "submit"],
  inputCodec: ResidencySubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: t.unknown,
});

export const PermanentAddressSubmitInput = withCoApplicant(
  t.type(
    {
      permanentAddress: AddressWrite,
    },
    "PermanentAddressSubmitInput"
  )
);
export interface PermanentAddressSubmitInput
  extends t.TypeOf<typeof PermanentAddressSubmitInput> {}
export const eqPermanentAddressSubmitInput: Eq<PermanentAddressSubmitInput> = eqWithCoApplicant(
  eq.getStructEq({
    permanentAddress: eqAddressWrite,
  })
);
export const permanentAddressSubmit = apiCall({
  inputEq: eqPermanentAddressSubmitInput,
  path: ["clients", "identification", "permanentAddress", "submit"],
  inputCodec: PermanentAddressSubmitInput,
});

export const ProofOfAddressSubmitInput = withCoApplicant(
  t.type(
    {
      proofOfAddress: PersonalDocumentProofOfAddress,
    },
    "ProofOfAddressSubmitInput"
  )
);
export interface ProofOfAddressSubmitInput
  extends t.TypeOf<typeof ProofOfAddressSubmitInput> {}
const eqProofOfAddressSubmitInput: Eq<ProofOfAddressSubmitInput> = eqWithCoApplicant(
  eq.getStructEq({
    proofOfAddress: eqPersonalDocumentOfAddress,
  })
);

export const ProofOfAddressSubmitError = t.strict({
  error: LocalizedString,
});
export type ProofOfAddressSubmitError = t.TypeOf<
  typeof ProofOfAddressSubmitError
>;
export const proofOfAddressSubmit = apiCall({
  path: ["clients", "identification", "proofOfAddress", "submit"],
  inputCodec: ProofOfAddressSubmitInput,
  inputEq: eqProofOfAddressSubmitInput,
  outputCodec: t.unknown,
  errorCodec: ProofOfAddressSubmitError,
});

export const ProofOfAddressDocumentSubmitInput = withCoApplicant(
  t.type(
    {
      side: t.keyof({
        First: true,
        Second: true,
      }),
      fileContent: CompressedFileContent,
    },
    "ProofOfAddressDocumentSubmitInput"
  )
);
export interface ProofOfAddressDocumentSubmitInput
  extends t.TypeOf<typeof ProofOfAddressDocumentSubmitInput> {}
const eqProofOfAddressDocumentSubmitInput: Eq<ProofOfAddressDocumentSubmitInput> = eqWithCoApplicant(
  eq.getStructEq({
    side: eq.eqStrict,
    fileContent: eq.eqStrict,
  })
);
export const proofOfAddressDocumentSubmit = apiCall({
  inputCodec: ProofOfAddressDocumentSubmitInput,
  inputEq: eqProofOfAddressDocumentSubmitInput,
  path: ["clients", "identification", "proofOfAddress", "document", "submit"],
});

export const ProofOfAddressDocumentRemoveInput = withCoApplicant(
  t.type(
    {
      side: t.keyof({
        First: true,
        Second: true,
      }),
    },
    "ProofOfAddressDocumentRemoveInput"
  )
);
export interface ProofOfAddressDocumentRemoveInput
  extends t.TypeOf<typeof ProofOfAddressDocumentRemoveInput> {}
const eqProofOfAddressDocumentRemoveInput: Eq<ProofOfAddressDocumentRemoveInput> = eqWithCoApplicant(
  eq.getStructEq({
    side: eq.eqStrict,
  })
);
export const proofOfAddressDocumentRemove = apiCall({
  inputCodec: ProofOfAddressDocumentRemoveInput,
  inputEq: eqProofOfAddressDocumentRemoveInput,
  path: ["clients", "identification", "proofOfAddress", "document", "remove"],
});

export const AdditionalDocumentUploadInput = withCoApplicant(
  t.type(
    {
      documentKey: NonResidentsAdditionalDocumentsType,
      fileContent: CompressedFileContent,
    },
    "AdditionalDocumentUploadInput"
  )
);
export interface AdditionalDocumentUploadInput
  extends t.TypeOf<typeof AdditionalDocumentUploadInput> {}
const eqAdditionalDocumentUploadInput: Eq<AdditionalDocumentUploadInput> = eqWithCoApplicant(
  eq.getStructEq({
    documentKey: eq.eqStrict,
    fileContent: eq.eqStrict,
  })
);
export const additionalDocumentUpload = apiCall({
  inputCodec: AdditionalDocumentUploadInput,
  inputEq: eqAdditionalDocumentUploadInput,
  path: ["clients", "identification", "additionalDocument", "upload"],
  outputCodec: t.unknown,
});

export const AdditionalDocumentRemoveInput = withCoApplicant(
  t.type(
    {
      documentKey: NonResidentsAdditionalDocumentsType,
    },
    "AdditionalDocumentRemoveInput"
  )
);
export interface AdditionalDocumentRemoveInput
  extends t.TypeOf<typeof AdditionalDocumentRemoveInput> {}
const eqAdditionalDocumentRemoveInput: Eq<AdditionalDocumentRemoveInput> = eqWithCoApplicant(
  eq.getStructEq({
    documentKey: eq.eqStrict,
  })
);
export const additionalDocumentRemove = apiCall({
  inputCodec: AdditionalDocumentRemoveInput,
  inputEq: eqAdditionalDocumentRemoveInput,
  path: ["clients", "identification", "additionalDocument", "remove"],
  outputCodec: t.type({
    atLeastOneDocUploaded: t.boolean,
  }),
});

export const AdditionalDocumentsRemoveInput = withCoApplicant(
  t.type(
    {
      documentKeys: t.array(NonResidentsAdditionalDocumentsType),
    },
    "AdditionalDocumentRemoveInput"
  )
);
export interface AdditionalDocumentsRemoveInput
  extends t.TypeOf<typeof AdditionalDocumentsRemoveInput> {}
const eqAdditionalDocumentsRemoveInput: Eq<AdditionalDocumentsRemoveInput> = eqWithCoApplicant(
  eq.getStructEq({
    documentKeys: eq.eqStrict,
  })
);
export const additionalDocumentsRemove = apiCall({
  inputCodec: AdditionalDocumentsRemoveInput,
  inputEq: eqAdditionalDocumentsRemoveInput,
  path: ["clients", "identification", "additionalDocument", "removeMany"],
  outputCodec: t.type({
    atLeastOneDocUploaded: t.boolean,
  }),
});

const AddressValidationInput = t.type(
  {
    address: AddressWrite,
  },
  "AddressValidationInput"
);

interface AddressValidationInput
  extends t.TypeOf<typeof AddressValidationInput> {}

const eqAddressValidateInput: Eq<AddressValidationInput> = eq.getStructEq({
  address: eqAddressWrite,
});

const AddressValidationOutput = t.type({
  zipCode: optionFromUndefined(NonEmptyString),
});

const AddressValidationError = t.type({
  id: t.union([t.literal("InvalidFlowId"), t.literal("InvalidAddress")]),
});

const AddressValidationErrorWithSuggestion = t.intersection([
  t.partial({
    id: t.union([t.literal("InvalidFlowId"), t.literal("InvalidAddress")]),
  }),
  t.partial({
    addresses: nonEmptyArray(AddressWrite),
  }),
]);

export type AddressValidationError = t.TypeOf<typeof AddressValidationError>;

export const addressValidation = apiCall({
  inputEq: eqAddressValidateInput,
  inputCodec: AddressValidationInput,
  path: ["clients", "identification", "address", "validate"],
  outputCodec: AddressValidationOutput,
  errorCodec: AddressValidationErrorWithSuggestion,
});

const DocumentAuthoritiesOutput = t.array(LocalizedString);
export const documentAuthorities = apiCall({
  path: ["clients", "identification", "documentAuthorities"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: DocumentAuthoritiesOutput,
});

const CountryOfBirthSubmitInput = withApplicantIndex(
  t.type(
    {
      countryOfBirth: CountryCode,
      idType: optionFromUndefined(DocumentPurpose),
    },
    "CountryOfBirthSubmitInput"
  )
);
export interface CountryOfBirthSubmitInput
  extends t.TypeOf<typeof CountryOfBirthSubmitInput> {}
export const countryOfBirthSubmit = apiCall({
  path: ["clients", "identification", "countryOfBirth", "submit"],
  inputCodec: CountryOfBirthSubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: GenericError,
});

const SecondCitizenshipSubmitInput = withApplicantIndex(
  t.type(
    {
      secondCitizenship: AllCitizenships,
    },
    "SecondCitizenshipSubmitInput"
  )
);
export type SecondCitizenshipSubmitInput = t.TypeOf<
  typeof SecondCitizenshipSubmitInput
>;
export const secondCitizenshipSubmit = apiCall({
  path: ["clients", "identification", "secondCitizenship", "submit"],
  inputCodec: SecondCitizenshipSubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: GenericError,
});

export const personalProfileDocUploadSubmit = apiCall({
  path: ["clients", "personalProfile", "docUpload", "submit"],
  inputCodec: PersonalInfoSubmitInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  errorCodec: PersonalProfileDocUploadSubmitError,
});

export const resetUploadAttempts = apiCall({
  path: ["clients", "identification", "resetUploadAttempts"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const ignoreAddressValidation = apiCall({
  path: [
    "clients",
    "identification",
    "personalInfo",
    "edit",
    "permanentAddress",
    "ignoreAddressValidation",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});
